import React from 'react';
import './BenefitsSection.css';
import checkImage from '../../../assets/images/home/check.png';

function BenefitsSection() {
    const benefits = [
        { title: '100% Gratis' },
        { title: 'Precios Actualizados' },
        { title: 'Compara Precios' }
    ];

    return (
        <div className="benefits-section-wrapper">
            <h2 className="benefits-title">Beneficios</h2>
            <section className="new-section container mt-5 text-center">
                <svg className="section-background" xmlns="http://www.w3.org/2000/svg" width="1347" height="267"
                    viewBox="0 0 1347 267" fill="none">
                    <rect width="1347" height="267" rx="30" fill="#13C670" fillOpacity="0.06" />
                </svg>
                <div className="benefits-content">
                    {benefits.map((benefit, index) => (
                        <BenefitItem key={index} title={benefit.title} />
                    ))}
                </div>
            </section>
        </div>
    );
    
}

const BenefitItem = ({ title }) => (
    <div className="benefit-item">
        <div className="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="84.132" height="84.132" viewBox="0 0 84.132 84.132"
                fill="none">
                <circle cx="42.066" cy="42.066" r="42.066" fill="#13C670" fillOpacity="0.15" />
                <image x="25.1685" y="25.1685" width="32.663" height="32.663" href={checkImage} />
            </svg>
        </div>
        <h4>{title}</h4>
    </div>
);

export default BenefitsSection;
