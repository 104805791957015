import React, { useState } from "react";
import "./Footer.css";

function Footer() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(false);
    setSubmitError(false);
    const formData = new FormData(event.target);
    const response = await fetch("https://formspree.io/f/xwkgkaog", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      setIsSubmitted(true);
      setTimeout(() => setIsSubmitted(false), 7000);
    } else {
      setSubmitError(true);
      setTimeout(() => setSubmitError(false), 7000);
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-brand">
            <h1 className="footer-title">Materix</h1>
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-official"></i>
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
            </div>
            <small>© 2024 Materix All Rights Reserved</small>
          </div>
          <div className="footer-contact">
            <h3>¿Quieres saber más?</h3>
            <h5>Comunícate con nosotros</h5>
            <form className="contact-form" onSubmit={handleSubmit}>
              {isSubmitted && (
                <p className="success-message">Mensaje enviado con éxito!</p>
              )}
              {submitError && (
                <p className="error-message">Error al enviar el mensaje.</p>
              )}
              <div className="message-card">
                <div className="input-group">
                  <i className="fas fa-sms lupa fa-lg"></i>
                  <textarea
                    name="message"
                    placeholder="Mensaje..."
                    className="contact-input"
                    ></textarea>
                  <button type="submit" className="contact-button">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
