import React from 'react';
import './Navbar.css';
import logoMule from './Logo_Materix.png';

function Navbar() {

    return (
        <nav className="navbar">
            <div className="container">
                <img src={logoMule} alt="Logo de Mule" className="navbar-logo" />
                <form className="search-form" onSubmit={(e) => e.preventDefault()}>
                    <div className="input-group">
                        <div className="search-input-wrapper">
                            <div className="search-icon">
                            {/* Icono de búsqueda */}
                            {<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                            fill="none">
                            <path
                                d="M9.35995 0.568359C4.50503 0.568359 0.569336 4.50405 0.569336 9.35896C0.569336 14.2139 4.50503 18.1496 9.35995 18.1496C11.4147 18.1496 13.3049 17.4446 14.8016 16.2632L18.5789 20.0405C18.9828 20.4444 19.6376 20.4444 20.0415 20.0405C20.4454 19.6366 20.4454 18.9818 20.0415 18.5779L16.2642 14.8007C17.4456 13.3039 18.1506 11.4138 18.1506 9.35896C18.1506 4.50405 14.2149 0.568359 9.35995 0.568359Z"
                                fill="#09244B" />
                            </svg>}
                            </div>
                            <input
                                id="search-input"
                                className="search-input"
                                type="search"
                                placeholder="Próximamente..."
                                readOnly // Hace el campo de búsqueda de solo lectura
                            />
                        </div>
                    </div>
                </form>
            </div>
        </nav>
    );
}

export default Navbar;
