import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import FirstSection from './sections/FirstSection/FirstSection';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import CategorySection from './sections/CategorySection/CategorySection';
import BenefitsSection from './sections/BenefitsSection/BenefitsSection';

function Home() {
    return (
        <div>
            <Navbar />
            <FirstSection />
            <HowItWorks/>
            <CategorySection/>
            <BenefitsSection/>
            <Footer />
        </div>
    );
}

export default Home;
