import React from 'react';
import './HowItWorks.css';
import calculatorImage from '../../../assets/images/home/Calculator.png';

function HowItWorks() {
    return (
        <div className="full-width-container">
            <div className="svg-right-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="239" height="318" viewBox="0 0 239 318" fill="none">
                    <path d="M238.856 159C238.856 202.813 203.171 238.499 159.358 238.499C115.544 238.499 79.8589 202.813 79.8589 159C79.8589 115.187 115.544 79.5012 159.358 79.5012C203.171 79.5012 238.856 115.187 238.856 159ZM286.202 159C286.202 142.746 298.569 128.261 318 118.721C314.113 103.174 307.755 88.3344 299.626 74.9073C279.485 81.9733 260.407 80.5611 248.746 69.2535C237.439 57.9458 235.674 38.5146 243.093 18.3737C229.666 10.2478 214.826 3.88661 199.279 0C190.093 19.4337 175.606 31.7982 159 31.7982C142.394 31.7982 128.261 19.4305 118.721 0C103.174 3.88673 88.3344 10.2452 74.9073 18.3737C81.9733 38.5146 80.5611 57.9458 69.2535 69.2535C57.9458 80.5613 38.5146 82.326 18.3737 74.9073C10.2478 88.3344 3.88661 103.174 0 118.721C19.4337 127.907 31.7982 142.394 31.7982 159C31.7982 175.606 19.4305 189.739 0 199.279C3.88673 214.826 10.2452 229.666 18.3737 243.093C38.5146 236.027 57.9458 237.439 69.2535 248.746C80.5613 260.054 82.326 279.485 74.9073 299.626C88.3344 307.752 103.174 314.113 118.721 318C127.907 298.566 142.394 286.202 159 286.202C175.606 286.202 189.739 298.569 199.279 318C214.826 314.113 229.666 307.755 243.093 299.626C236.027 279.485 237.439 260.054 248.746 248.746C260.054 237.439 279.485 235.674 299.626 243.093C307.752 229.666 314.113 214.826 318 199.279C298.569 189.739 286.202 175.254 286.202 159Z" fill="#13C670" fillOpacity="0.1"/>
            </svg>
            </div>
        <section className="how-it-works container mt-5 text-center">
            <h2 className="how-it-works-title title-text">¿Cómo funciona?</h2>
            <div className="how-it-works-grid mt-4">
                <HowItWorksItem 
                    icon={<Icon1 />} 
                    step="1. BUSCÁ"
                    description="Carga tu lista de materiales."
                />
                <HowItWorksItem 
                    icon={<Icon2 />}
                    step="2. COMPARÁ"
                    description="Los distintos proveedores y precios"
                />
                <HowItWorksItem 
                    icon={<Icon3 />}
                    step="3. CALCULÁ"
                    description="El presupuesto de tu proyecto."
                />
            </div>
        </section>
        </div>
    );
}

const HowItWorksItem = ({ icon, step, description }) => (
    <div className="how-it-works-item">
        <div className="how-it-works-icon-container">
            {icon}
        </div>
        <h4 className="how-it-works-step">{step}</h4>
        <p className="how-it-works-desc">{description}</p>
    </div>
);

// Replace the following with your actual SVG content
const Icon1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="182" height="182" viewBox="0 0 182 182" fill="none">
    <g filter="url(#filter0_b_7_86)">
        <circle cx="91" cy="91" r="91" fill="#00A3FF" fillOpacity="0.08" />
        <circle cx="91" cy="91" r="90.5" stroke="#1794FA" />
    </g>
    <path
        d="M88.2273 62C73.7424 62 62 73.7423 62 88.2273C62 102.712 73.7424 114.455 88.2273 114.455C94.3579 114.455 99.9972 112.351 104.463 108.827L115.733 120.096C116.938 121.301 118.891 121.301 120.096 120.096C121.301 118.891 121.301 116.938 120.096 115.733L108.827 104.463C112.351 99.9972 114.455 94.3579 114.455 88.2273C114.455 73.7423 102.712 62 88.2273 62Z"
        fill="#09244B" />
    <defs>
        <filter id="filter0_b_7_86" x="-169.12" y="-169.12" width="520.24" height="520.24"
            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.56" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7_86" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7_86"
                result="shape" />
        </filter>
    </defs>
</svg>
);

const Icon2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="182" height="182" viewBox="0 0 182 182" fill="none">
    <g filter="url(#filter0_b_7_87)">
        <circle cx="91" cy="91" r="91" fill="#00A3FF" fillOpacity="0.08" />
        <circle cx="91" cy="91" r="90.5" stroke="#1794FA" />
    </g>
    <path
        d="M115.825 78.7779L106.37 69.1913C104.804 67.6029 102.264 67.6029 100.697 69.1913C99.1309 70.7796 99.1309 73.3549 100.697 74.9433L103.304 77.5867H80.8029C78.5875 77.5867 76.7916 79.4076 76.7916 81.6539C76.7916 83.9002 78.5875 85.7212 80.8029 85.7212H103.304L100.697 88.3646C99.1309 89.953 99.1309 92.5282 100.697 94.1166C102.264 95.705 104.804 95.705 106.37 94.1166L115.825 84.5299C117.392 82.9416 117.392 80.3663 115.825 78.7779Z"
        fill="#09244B" />
    <path
        d="M67.1749 106.222C65.6084 104.634 65.6084 102.058 67.1749 100.47L76.6297 90.8834C78.1962 89.295 80.7361 89.295 82.3026 90.8834C83.8691 92.4718 83.8691 95.047 82.3026 96.6354L79.6955 99.2788H102.197C104.412 99.2788 106.208 101.1 106.208 103.346C106.208 105.592 104.412 107.413 102.197 107.413H79.6955L82.3026 110.057C83.8691 111.645 83.8691 114.22 82.3026 115.809C80.7361 117.397 78.1962 117.397 76.6297 115.809L67.1749 106.222Z"
        fill="#09244B" />
    <defs>
        <filter id="filter0_b_7_87" x="-169.12" y="-169.12" width="520.24" height="520.24"
            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.56" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7_87" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7_87"
                result="shape" />
        </filter>
    </defs>
</svg>
);

const Icon3 = () => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="182"
    height="182" viewBox="0 0 182 182" fill="none">
    <g filter="url(#filter0_b_7_93)">
        <circle cx="91" cy="91" r="91" fill="#00A3FF" fillOpacity="0.08" />
        <circle cx="91" cy="91" r="90.5" stroke="#1794FA" />
        <image x="61" y="61" width="60" height="60" xlinkHref={calculatorImage} />
    </g>
    <defs>
        <filter id="filter0_b_7_93" x="-169.12" y="-169.12" width="520.24" height="520.24"
            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="84.56" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7_93" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7_93"
                result="shape" />
        </filter>
    </defs>
</svg>

);

export default HowItWorks;
